import axios from 'axios';
import authHeader from './auth-header';
import store from '../store'

const API_URL = 'https://api.srbasar.de/api/user/';
//const API_URL = 'http://localhost:3000/api/user/';

class UserService {
  updatePassword(password) {
    return axios.post(API_URL + 'update', {password: password}, {headers: authHeader()});
  }

  updateData(object) {
    return axios.post(API_URL + 'update', object, {headers: authHeader()}).then(() => {
      store.commit("auth/updateUser", object)
    });
  }

  list() {
    return axios.get(API_URL + 'list', {headers: authHeader()}).then(response => response.data);
  }
}

export default new UserService();
