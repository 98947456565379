<template>
  <div class="profile mt-5">

    <div class="container">
      <div class="mb-3 row">
        <h5>Benutzer</h5>
      </div>
      <div class="mb-3 row">
        <label class="col-sm-4 col-form-label" for="staticEmail"><strong>Email</strong></label>
        <div class="col-sm-8">
          <input id="staticEmail" v-model="user.email" class="form-control-plaintext  text-center text-sm-start text-white"
                 readonly
                 type="text">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-sm-4 col-form-label" for="staticEmail"><strong>Vereine</strong></label>
        <div class="col-sm-8 ">
          <input v-for="(club, index) in user.club" :key="index" :value="club"
                 class="form-control-plaintext text-center text-sm-start text-white" readonly type="text">
        </div>
      </div>
      <hr class="my-5 text-white">
      <div class="mb-3 row">
        <h5>Sicherheit</h5>
      </div>
      <div class="mb-3 row needs-validation mx-2">
        <label class="col-sm-4 col-form-label" for="inputPassword"><strong>Passwort</strong></label>
        <div class="col-sm-8">
          <input v-model="pass" :class="{'is-invalid': !validate&&!empty, 'is-valid': validate}" class="form-control"
                 type="password">
        </div>
      </div>
      <div class="mb-3 row mx-2">
        <label class="col-sm-4 col-form-label" for="inputPassword"><strong>Bestätigung</strong></label>
        <div class="col-sm-8">
          <input v-model="control" :class="{'is-invalid': !validate&&!empty, 'is-valid': validate}" class="form-control"
                 type="password">
        </div>
        <div ref="feedback" class="feedback text-danger mt-2">
        </div>
        <div ref="valid" class="feedback text-success mt-2">
        </div>


          <button class="btn btn-outline-light" type="button" @click="updatePass">Passwort ändern</button>

      </div>
      <hr class="my-5 text-white">
      <div class="mb-3 row">
        <h5>Kontaktdaten</h5>
      <p><i>Felder speichern Änderungen automatisch</i></p>
      </div>

      <div class="mb-3 d-flex justify-content-around flex-wrap">
        <input id="getEmail" v-model="getEmails" autocomplete="off" class="btn-check" type="checkbox"
               @change="updateData">
        <label v-if="getEmails" class="btn btn-outline-success mt-2" for="getEmail">E-Mail-Formularfeld anzeigen</label>
        <label v-else class="btn btn-outline-danger mt-2" for="getEmail">E-Mail-Formularfeld nicht anzeigen</label>

        <input id="showContact" v-model="showContact" autocomplete="off" class="btn-check" type="checkbox"
               @change="updateData">
        <label v-if="showContact" class="btn btn-outline-success mt-2" for="showContact">Telefonnummer nach aussen
          anzeigen</label>

        <label v-else class="btn btn-outline-danger mt-2" for="showContact">Telefonnummer nach aussen nicht anzeigen</label>
        <input id="whatsapp" v-model="whatsapp" autocomplete="off" class="btn-check" type="checkbox"
               @change="updateData">
        <label v-if="whatsapp && showContact" class="btn btn-outline-success mt-2" for="whatsapp">Whatsapp möglich</label>
        <label v-if="!whatsapp && showContact" class="btn btn-outline-danger mt-2" for="whatsapp">Whatsapp nicht
          möglich</label>

        <input id="showInfo" v-model="showInfo" autocomplete="off" class="btn-check" type="checkbox"
               @change="updateData">
        <label v-if="showInfo" class="btn btn-outline-success mt-2" for="showInfo">Kontaktinfo nach aussen
          anzeigen</label>
        <label v-else class="btn btn-outline-danger mt-2" for="showInfo">Kontaktinfo nach aussen nicht anzeigen</label>

        <input id="showMail" v-model="showMail" autocomplete="off" class="btn-check" type="checkbox"
               @change="updateData">
        <label v-if="showMail" class="btn btn-outline-success mt-2" for="showMail">E-Mail nach aussen
          anzeigen</label>
        <label v-else class="btn btn-outline-danger mt-2" for="showMail">E-Mail nach aussen nicht anzeigen</label>



      </div>
      <div class="mb-3 row">
        &nbsp;
      </div>
      <div >
       <div class="mb-3 row" >
          <MazInput v-model="name" label="Name" v-debounce:750ms="updateData"/>
        </div>
        <div class="mb-3 row" v-if="showContact">
          <MazPhoneNumberInput
              v-model="phone"
              :preferred-countries="['DE','FR', 'BE', 'US', 'GB']"
              :success="results?.isValid"
              :default-country-code="'DE'"
              :translations="{
      countrySelector: {
        placeholder: 'Länderkennung',
        error: 'Wähle ein Land',
      },
      phoneInput: {
        placeholder: 'Telefonnummer',
        example: 'Beispiel:',
      },
    }"
              color="info"
              show-code-on-list
              @update="results= $event;"
              v-debounce:750ms="updateData"
          />
        </div>


        <div class="form-floating" v-if="showInfo">
          <textarea id="contactInfo" v-model="contactInfo" v-debounce:750ms="updateData" class="form-control"
                    placeholder="Leave a comment here" style="height: 100px;"></textarea>
          <label for="contactInfo">Kontaktinfo</label>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import UserService from "@/services/user.service";
import {vue3Debounce} from 'vue-debounce'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInput from 'maz-ui/components/MazInput'

export default {
  name: 'ProfileView',
  components: {
    MazPhoneNumberInput,
    MazInput
  },
  data() {
    return {
      pass: null,
      control: null,
      validate: false,
      empty: true,
      contactInfo: null,
      phone: null,
      getEmails: null,
      showContact: null,
      whatsapp: null,
      showMail: false,
      showInfo: false,
      name: [],
      results: {},
      user: {},
      debouncing: {}
    }
  },
  directives: {
    debounce: vue3Debounce({lock: true})
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  methods: {
    async updatePass() {
      if (this.validation()) {
        await UserService.updatePassword(this.pass).then(() => {
          this.empty = true;
          this.control = ""
          this.pass = ""
          this.$refs.feedback.innerText = ""
          this.$refs.valid.innerText = "Passwort wurde geändert!"
        })
      }
    },
    async updateData() {
      await UserService.updateData({
        contactInfo: this.contactInfo,
        phone: this.phone,
        showContact: this.showContact,
        getEmails: this.getEmails,
        showMail: this.showMail,
        showInfo: this.showInfo,
        name: this.name,
        whatsapp: this.whatsapp
      })
    },
    validation() {
      if (!this.pass && !this.control) {
        this.empty = true
        this.$refs.feedback.innerText = ""
        this.validate = null
        return false;
      } else if (this.pass === this.control) {
        this.empty = false;
        this.$refs.feedback.innerText = ""
        this.validate = true
        return true;
      } else {
        this.empty = false;
        this.validate = false
        this.$refs.feedback.innerText = "Die Passwörter müssen übereinstimmen."
        return false;
      }
    },
  },
  watch: {
    pass() {
      this.validation()
    },
    control() {
      this.validation()
    }
  },
  created() {
    this.user = this.getUser
    this.contactInfo = this.user.contactInfo
    this.phone = this.user.phone
    this.firstName = this.user.firstName
    this.lastName = this.user.lastName
    this.showMail = this.user.showMail ? this.user.showMail : false
    this.showInfo = this.user.showInfo ? this.user.showInfo : false
    this.showContact = this.user.showContact ? this.user.showContact : false
    this.getEmails = this.user.getEmails ? this.user.getEmails : false
    this.name = this.user.name
    this.whatsapp = this.user.whatsapp ? this.user.whatsapp : false
  }

}
</script>
<style>

</style>
