import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ProfileView from "@/views/ProfileView";
import store from '../store/';
import UserView from "@/views/UserView";
import ResetView from "@/views/ResetView";
import LinkView from "@/views/LinkView.vue";
import ExternalView from "@/views/ExternalView.vue";
import AnswerView from "@/views/AnswerView.vue";
import AllView from "@/views/AllView.vue";

const expired = async (to,from,next)=>{

  if(store.getters['auth/getExpired']){
    await store.dispatch('auth/logout')
    next("/")
  }else{
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ansetzungen',
    name: 'ansetzungen',
    beforeEnter: expired,
    component: () => import(/* webpackChunkName: "about" */ '../views/GamesView.vue')
  },
  {
    path: '/historie',
    name: 'historie',
    beforeEnter: expired,
    component: () => import(/* webpackChunkName: "about" */ '../views/HistoryView.vue'),
    props: {allGames: true}
  }, {
    path: '/login',
    name: 'login',
    component: LoginView
  }, {
    path: '/reset',
    name: 'reset',
    component: ResetView
  },{
    path: '/links',
    name: 'links',
    component: LinkView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter: expired
  }
  , {
    path: '/user',
    name: 'user',
    component: UserView,
    beforeEnter: expired
  },{
  path: '/link/:link?',
    name: 'link',
    component: ExternalView,
  },
  {
    path: '/answer/:link?',
    name: 'answer',
    component: AnswerView,
  },
  {
    path: '/all',
      name: 'all',
      component: AllView,
    }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
