import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = 'https://api.srbasar.de/api/auth/';
//const API_URL = 'http://localhost:3000/api/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      email: user.email,
      club: user.club
    }, {headers: authHeader()});
  }

  delete(user) {
    return axios.post(API_URL + 'user', {
      email: user.email,
    }, {headers: authHeader()});
  }

  reset(email) {
    return axios
        .post(API_URL + 'reset', {
          email: email
        })
        .then(response => {
          return response.data;
        });
  }
}

export default new AuthService();
