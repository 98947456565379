<template>
  <div class="user container">
    <div class="m-3">
      <h4>Benutzer hinzufügen</h4>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="staticEmail" v-model="email">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-sm-2 col-form-label">Vereine</label>
        <div class="col-sm-10">
          <VueMultiselect v-model="club" :options="options" :multiple="true" :searchable="true"
                          key="1"></VueMultiselect>
        </div>
      </div>
      <button class="btn btn-outline-dark" @click="register()">Anlegen</button>
    </div>

    <table class="table table-dark table-striped">
      <thead>
      <tr>
        <th scope="col">E-Mail</th>
        <th scope="col">Vereine</th>
        <th scope="col">Edit</th>
        <th scope="col">Delete</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="_u in user" :key="_u._id">
        <td>{{ _u.email }}</td>
        <td><span class="me-1" v-for="club in _u.club" :key="club">{{ club }}</span></td>
        <td @click="editHandler(_u)">
          <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
        </td>
        <td class="text-danger" @click="remove(_u.email)">
          <font-awesome-icon icon="fa-solid fa-trash"/>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
  <div v-if="edit" class="container mt-5">
    <div class="m-3">
      <h4>Benutzer editieren</h4>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="staticEmail" v-model="editEmail">
        </div>
      </div>
      <VueMultiselect v-model="editClub" :options="options" :multiple="true" :searchable="true"
                      key="2"></VueMultiselect>
    </div>
    <button class="btn btn-outline-dark" @click="save()">Speichern</button>
  </div>
</template>

<script>

import UserService from "@/services/user.service";
import VueMultiselect from 'vue-multiselect'
import GamesService from "@/services/games.service";
import AuthService from "@/services/auth.service";

export default {
  name: 'userView',
  components: {
    VueMultiselect
  },
  data() {
    return {
      user: [],
      club: null,
      email: "",
      options: ['list', 'of', 'options'],
      edit: false,
      editClub: [],
      editEmail: "",
      editId: ""
    }
  },
  methods: {
    async register() {
      await AuthService.register({email: this.email, club: this.club})
      this.user = await UserService.list()
    },
    async remove(email) {
      if (confirm("Wirklich löschen ?")) {
        await AuthService.delete({email: email})
        this.user = await UserService.list()
      }
    },
    editHandler(user) {
      this.edit = true;
      this.editClub = user.club;
      this.editEmail = user.email;
      this.editId = user._id;
    },
    async save() {
      await UserService.updateData({
        email: this.editEmail,
        id: this.editId,
        club: this.editClub

      }).then(() => {
        this.edit = false;
        this.editClub = "";
        this.editEmail = "";
        this.editId = "";
      })
      this.user = await UserService.list()
    }
  },
  async mounted() {
    this.user = await UserService.list()
    this.options = await GamesService.vereine()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>