<template>
  <div class="nocturnal text-white" v-if="loaded">
    <div v-if="onlyShow">
    <h2 class="p-2 bg-darker text-white">Ansetzungsübersicht</h2>

    <p v-if="onlyShow">Auf dieser Seite sind die Ansetzungen von <strong>{{ vereine.join(" und ") }}</strong> {{ getDateString }} zu
      sehen.
    </p>
      </div>
    <div v-else>
      <div v-if="!dataComplete">
      <h2 class="p-2 bg-darker text-white">Ansetzungen</h2>
      <p>
        Auf dieser Seite kannst du dich für die Ansetzungen von <strong>{{ vereine.join(" und ") }}</strong> {{ getDateString }} eintragen.
      </p>


        <div class="container-sm">
        <div class="border border-white pt-3 pb-5 px-5 rounded-3 mb-5 ">
          <h3 class="p-2 bg-darker text-white"> Wer bin ich?</h3>
          <p>Bevor du die Daten sehen kannst, muss du dich erst identifizieren:</p>
        <div class="mb-3">
          <label class="form-label" for="exampleFormControlInput1">Name</label>
          <input id="text" v-model="contactName" class="form-control" placeholder="Name" type="text">
        </div>
        <select v-model="contactLizenz" class="form-select mb-3">
          <option disabled value="-1">Lizenz wählen</option>
          <option value="LSE">LSE</option>
          <option value="LSE">LSE+</option>
          <option value="LSD">LSD oder höher</option>
        </select>
        <div class="mb-3">
          <label class="form-label" for="email">E-Mail-Adresse</label>
          <input id="email" v-model="contactEmail" class="form-control"
                 placeholder="E-Mail-Adresse" type="text">
          <label class="form-label" for="mobile">Handynummer</label>
          <MazPhoneNumberInput
              v-model="contactMobile"
              :preferred-countries="['DE','FR', 'BE', 'US', 'GB']"
              :success="results?.isValid"
              @update="results= $event;"
              :default-country-code="'DE'"
              :translations="{
                              countrySelector: {
                                placeholder: 'Länderkennung',
                                error: 'Wähle ein Land',
                              },
                              phoneInput: {
                                placeholder: 'Telefonnummer',
                                example: 'Beispiel:',
                              },
                            }"
              color="info"
              show-code-on-list
          />
        </div>
          <p class="text-danger my-2" v-if="errorMessage"><strong v-html="errorMessage"></strong></p>
          <div class="form-check-inline">
            <input id="flexCheckDefault" v-model="contactSaveInfo" class="form-check-input" type="checkbox"
                   value="">
            <label class="form-check-label" for="flexCheckDefault">
              &nbsp;Daten speichern
            </label>
          </div>
          <div>
            <button class="btn btn-outline-light" @click="forward">Weiter</button>
          </div>

        </div>
        </div>
      </div>
      <div v-else-if="dataComplete && !overview">
        <h2 class="p-2 bg-darker text-white">Ansetzungen auswählen</h2>
        <p>Bitte wähle die Spiele, die du gerne übernehmen möchtest aus, eine Mehrfachnennung ist möglich und klicke dann auf absenden. Den Button findest du nach Auswahl von Spielen oberhalb der Tabelle. </p>
        <div class="btn-group my-3" role="group">

          <button type="button" class="btn btn-outline-light" v-if="contactName" disabled>{{contactName}}</button>
          <button type="button" class="btn btn-outline-light" v-if="contactLizenz !== -1" disabled>{{contactLizenz}}</button>
          <button type="button" class="btn btn-outline-light" v-if="contactEmail" disabled>{{contactEmail}}</button>
          <button type="button" class="btn btn-outline-light" v-if="contactMobile" disabled>{{contactMobile}}</button>
          <button class="btn btn-outline-danger" @click="dataComplete = !dataComplete">Daten bearbeiten</button>
        </div>
        <div class="container-sm">
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">Nachricht an den*die Schiedsrichterwart*in:</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" v-model="contactMessage" rows="3"></textarea>
          </div>
            <button type="button" class="btn btn-outline-light my-3" :disabled="selectedRows.length === 0" @click="overview = !overview">{{selectedRows.length === 0 ? `Bitte zuerst mindestens ein Spiel auswählen`: `Übernahme von ${selectedRows.length} Spielen absenden`}}</button>
        </div>
      </div>
      <div v-else>
        <h2 class="p-2 bg-darker text-white">Ansetzungen bestätigen</h2>
        <p>Bitte überprüfe die Spiele, die du ausgewählt hast. </p>
        <div>
          <div class="container-sm">
          <ul class="list-group">
            <li v-for="(game, index) in selectedRows" :key="index" class="list-group-item nocturnal text-white d-flex">
              <input class="form-check-input me-1" type="checkbox" :value="index" :id="index" checked @click="uncheck">
              <label class="form-check-label flex-fill" :for="index">
              <div class="d-flex justify-content-evenly">
                <div>
                  {{this.convertToGermanDate(game.kickoffDate)}}
                </div>
                <div>
                  {{game.kickoffTime}}
                </div>
                <div>
                  {{game.spielfeld}}
                </div>
                <div>
                  {{game.liganame}}
                </div>
              </div>
              </label>
            </li>
          </ul>
            </div>
        </div>
        <div class="form-check-inline mt-2">
          <input id="flexCheckDefault" v-model="sendMailCopy" class="form-check-input" type="checkbox"
                 value="">
          <label class="form-check-label" for="flexCheckDefault">
            &nbsp;Kopie der Spiele als Mail erhalten?
          </label>
        </div>
        <p>Bitte beachte, dass du mit dieser Auswahl lediglich den Wunsch äußerst die Spiele zu übernehmen, dies <strong><u>ist keine</u></strong> verbindliche Zusage des Vereines!</p>
        <div>
          <div class="btn-group my-3" role="group">
            <button type="button" class="btn btn-outline-success" @click="sendAnswer">Absenden</button>
            <button type="button" class="btn btn-outline-danger" @click="overview = !overview">Zurück zur Liste</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <vue-good-table
      ref="myTable"
      :class="'m-0'"
      :columns="columns"
      :pagination-options="pagination"
      :rows="matches"
      :search-options="search"
      :select-all-by-page="false"
      :select-options="{
              enabled: !this.link.onlyShow,
              selectionInfoClass: 'nocturnal text-white',
              selectionText: 'Ausgewählte Spiele',
              clearSelectionText: 'löschen',
              selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
      :sort-options="sort"
      theme="nocturnal"
      v-on:selected-rows-change="selectionChanged"
      v-if="onlyShow || (!onlyShow && dataComplete && !overview)"
  >
    <template #emptystate>
      Es sind keine Spiele vorhanden.
    </template>
  </vue-good-table>
  <button v-if="link.onlyShow" class="btn btn-outline-dark" @click="ical">Liste Filtern => ICal Downloaden
    <font-awesome-icon icon="fa-solid fa-download"/>
  </button>
</template>

<script>
import LinkService from "@/services/link.service";
import {pagination, search, sort, fieldFn} from '@/config/datatable.config'
import {format} from "date-fns";
import {generateICal} from "@/services/ical.service";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
export default {
  name: 'ExternalView',
  components: {MazPhoneNumberInput},
  data() {
    return {
      contactName: null,
      contactLizenz: "-1",
      contactMobile: null,
      contactEmail: null,
      errorMessage: "",
      contactMessage: "",
      contactSaveInfo: false,
      uncheckedList: [],
      overview: false,
      sendMailCopy: true,
      results: {},
      matches: [],
      vereine: [],
      loaded: false,
      dataComplete: false,
      onlyShow: false,
      selectedRows: [],
      link: {},
      search: search,
      pagination: pagination,
      columns: [
        {
          label: 'Datum',
          field: 'kickoffDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy',
          tdClass: 'text-center',
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: function (data, filterString) {
              return format(new Date(data), "dd.MM.yyyy").includes(filterString)
            }, //custom filter function that
            trigger: 'keyup', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Halle',
          field: 'spielfeld',
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
          tdClass: 'text-center'
        },
        {
          label: 'Liga',
          field: 'liganame',
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
          tdClass: 'text-center'
        },
        {
          label: 'Zeit',
          field: 'kickoffTime',
          type: 'date',
          tdClass: 'text-center',
          thClass: 'text-center',
          dateInputFormat: 'HH:mm',
          dateOutputFormat: 'HH:mm',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Heim',
          field: 'homeTeam',
          type: 'text',
          tdClass: 'text-center',
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Gast',
          field: 'guestTeam',
          type: 'text',
          tdClass: 'text-center',
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: '1.SR',
          field: this.sr1,
          html: true,
          type: 'text',
          tdClass: (row) => {
            const extra = row.sr1Besetzt === true ? "besetzt" : "offen"
            const add = row.sr1Basar === true && row.sr1Besetzt === false ? "basar" : extra
            const other = !this.vereine.includes(row.sr1) ? "otherClub" : ""
            return 'text-center' + " " + add + " " + other
          },
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: '2.SR',
          field: this.sr2,
          html: true,
          type: 'text',
          tdClass: (row) => {
            const extra = row.sr2Besetzt === true ? "besetzt" : "offen"
            const add = row.sr2Basar === true && row.sr2Besetzt === false ? "basar" : extra
            const other = !this.vereine.includes(row.sr2) ? "otherClub" : ""
            return 'text-center' + " " + add + " " + other
          },
          thClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Lizenz',
          field: fieldFn,
          tdClass: 'text-center',
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: ["LSE", "LSE+ | LSD", "LSD"],
          },
        },
      ],
      sort: sort,
    }
  }, methods: {
    ical() {
      generateICal(this.$refs['myTable'].processedRows[0].children, this.vereine)
    },
    selectionChanged(params) {
      this.selectedRows = params.selectedRows

    },
    text(text){
      if(text === "basar"){
        return "im Basar"
      }
      return text
    },
    convertToGermanDate(englishDateString) {
      // Aufteilen des Eingabe-Strings in Jahr, Monat und Tag
      const [year, month, day] = englishDateString.split('-');

      // Erstellen eines Date-Objekts
      const date = new Date(year, month - 1, day); // Monate sind in JavaScript 0-basiert

      // Überprüfen, ob das Date-Objekt gültig ist
      if (isNaN(date.getTime())) {
        return "Ungültiges Datum";
      }

      // Erstellen eines deutschen Datums-Strings
      const germanDateString = date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });

      return germanDateString;
    },
    refInclude(team) {
      return !!this.vereine.includes(team);
    },
    forward(){
      if(this.completed){
        this.errorMessage = ""
        this.dataComplete = true;
      }else{
        if(this.contactLizenz == "-1"){
          this.errorMessage += "Bitte Lizenzstufe angeben!"
        }
        if(!this.contactName){
          if(this.errorMessage !== ""){
            this.errorMessage += "<br />"
          }
          this.errorMessage += "Bitte Namen angeben!"
        }
        if(!this.contactEmail){
          if(this.errorMessage !== ""){
            this.errorMessage += "<br />"
          }
          this.errorMessage += "Bitte Email-Adresse angeben!"
        }
        if(!this.istGueltigeEmail(this.contactEmail)){
          if(this.errorMessage !== ""){
            this.errorMessage += "<br />"
          }
          this.errorMessage += "Bitte gültige Email-Adresse angeben!"
        }
      }
    },
    sr1(row){
      let name = ""
      if (this.refInclude(row.sr1) && row.sr1Besetzt) {
        name = row.sr1Name ? row.sr1Name : "besetzt"
      } else if (this.refInclude(row.sr1) && !row.sr1Besetzt) {
        name = "offen"
      }else if(!this.refInclude(row.sr1)){
        const extra = row.sr1Besetzt === true ? "besetzt" : "offen"
        const add = row.sr1Basar === true && row.sr1Besetzt === false ? "basar" : extra
        name = `<span class='${add}'>&nbsp;${this.text(add)}&nbsp;</span>`
      }
      return `${row.sr1}<br/> <i>${name}</i>`
    },
    sr2(row) {
      let name = ""
      if (this.refInclude(row.sr2) && row.sr2Besetzt) {
        name = row.sr2Name ? row.sr2Name : "besetzt"
      } else if (this.refInclude(row.sr2) && !row.sr2Besetzt) {
        name = "offen"
      }
      else if(!this.refInclude(row.sr2)){
        const extra = row.sr2Besetzt === true ? "besetzt" : "offen"
        const add = row.sr2Basar === true && row.sr2Besetzt === false ? "basar" : extra
        name = `<span class='${add}'>&nbsp;${this.text(add)}&nbsp;</span>`
      }
      return `${row.sr2}<br/> <i>${name}</i>`
    },
    updateLocal() {
      if (this.contactSaveInfo) {
        localStorage.setItem('contact', JSON.stringify({
          name: this.contactName,
          mobile: this.contactMobile,
          email: this.contactEmail,
          lizenz: this.contactLizenz,
          saveInfo: this.contactSaveInfo
        }));
      }
    },
    istGueltigeEmail(email) {
      // Definiere die Regex für eine einfache E-Mail-Adresse
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Überprüfe, ob der übergebene String der Regex entspricht
      return emailRegex.test(email);
    },
    async sendAnswer(){
      await LinkService.answer({
        link: this.link._id,
        name: this.contactName,
        email: this.contactEmail,
        telefon: this.contactMobile,
        lizenzstufe: this.contactLizenz,
        message: this.contactMessage,
        games: this.selectedRows.filter( (row, index) => !this.uncheckedList.includes(index)),
        sendMail: this.sendMailCopy
      }).then(() => this.$router.replace({name: "home"}))

    },
    uncheck(e){
      if(!e.target.checked){
        this.uncheckedList.push(parseInt(e.target.id))
      }else{
        this.removeItemFromArray(this.uncheckedList, parseInt(e.target.id))
      }
    },
    removeItemFromArray(array, item) {
      const index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
  },
  computed: {
    getDateString() {
      if (this.link.start && this.link.end) {
        return `von ${this.convertToGermanDate(this.link.start)} bis ${this.convertToGermanDate(this.link.end)}`
      } else if (this.link.start) {
        return `ab ${this.convertToGermanDate(this.link.start)}`
      }else if (this.link.end) {
        return `ab ${this.convertToGermanDate(this.link.end)}`
      }else {
        return ``
      }
    },
    completed(){
      if(this.contactLizenz !== "-1" && this.contactName && this.contactEmail && this.istGueltigeEmail(this.contactEmail)){
        return true
      }
      return false
    }
  },
  async mounted() {
    const contact = JSON.parse(localStorage.getItem('contact'));
    if (contact) {
      this.contactName = contact.name
      this.contactEmail = contact.email
      this.contactMobile = contact.mobile
      this.contactLizenz = contact.lizenz
      this.contactSaveInfo = contact.saveInfo
      if(this.completed){
        this.dataComplete = true;
      }
    }
    if (!this.$route.params.link) {
      this.$router.replace({name: "home"})
    } else {
      const res = await LinkService.getGames(this.$route.params.link)
      if(res === false){
        this.$router.replace({name: "home"})
      }
      this.vereine = JSON.parse(res.club)
      this.matches = res.matches
      this.link = res.link
      this.onlyShow = res.link.onlyShow

    }
    this.loaded =true;
  },
  watch: {
    contactName() {
      this.updateLocal()
    },
    contactLizenz() {
      this.updateLocal()
    },
    contactEmail() {
      this.updateLocal()
    },
    contactMobile() {
      this.updateLocal()
    },
    contactSaveInfo() {
      this.updateLocal()
    }
  }
};
</script>

