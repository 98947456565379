// Importing necessary modules and components
import {createApp} from 'vue' // Vue application instance creator
import App from './App.vue' // Main application component
import router from './router' // Router configuration
import VueGoodTablePlugin from 'vue-good-table-next'; // Plugin for creating tables
import store from './store/'; // Vuex store configuration
import 'vue-good-table-next/dist/vue-good-table-next.css' // Styles for vue-good-table-next
import './plugins' // Custom plugins
import {library} from '@fortawesome/fontawesome-svg-core' // Font Awesome library
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome' // Font Awesome component for Vue
import './styles/app.css' // Main application styles
import JsonExcel from "vue-json-excel3"; // Plugin for exporting JSON data to Excel
import 'maz-ui/css/main.css' // Styles for Maz UI

// Importing necessary Font Awesome icons
import {
    faArrowsLeftRightToLine,
    faCircleInfo,
    faDownload,
    faHome,
    faHouse,
    faEye,
    faMobile,
    faPenToSquare,
    faSignInAlt,
    faSignOutAlt,
    faSuitcase,
    faTrash,
    faUser,
    faList,
    faUserPlus,
    faMugHot
} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope, faMoneyBill1} from '@fortawesome/free-regular-svg-icons'

// Adding the imported icons to the Font Awesome library
library.add(faHome, faUser, faEye, faUserPlus, faEnvelope, faMobile, faSignInAlt, faSignOutAlt, faHouse, faArrowsLeftRightToLine, faSuitcase, faMoneyBill1, faCircleInfo, faPenToSquare, faTrash, faList, faDownload, faMugHot);

// Creating the Vue application instance
createApp(App)
  .use(router) // Using the router
  .use(store) // Using the Vuex store
  .use(VueGoodTablePlugin) // Using the vue-good-table-next plugin
  .component("downloadExcel", JsonExcel) // Registering the JsonExcel component
  .component('font-awesome-icon', FontAwesomeIcon) // Registering the FontAwesomeIcon component
  .mount('#app') // Mounting the application to the DOM