import axios from 'axios';
import authHeader from "@/services/auth-header";
import store from '../store/';

const API_URL = 'https://api.srbasar.de/api/ref/';
const API_UPDATE = 'https://api.srbasar.de/api/update/ref/';

//const API_URL = 'http://localhost:3000/api/ref/';
//const API_UPDATE = 'http://localhost:3000/api/update/ref/';

class GamesService {
  get() {
    return axios
        .get(API_URL, { headers: authHeader() } )
      .then(response => {
          return response.data;
      }).catch(async () => {
            await store.dispatch('auth/logout')
        });
  }

  all() {
    return axios
        .get(API_URL + "all", { headers: authHeader() } )
      .then(response => {
          return response.data;
      }).catch(async () => {
            await store.dispatch('auth/logout')
        });
  }

    basar() {
        return axios
            .get(API_URL + "basar")
            .then(response => {
                return response.data;
            })
    }

    uebernehmen(match, payload) {
        return axios
            .post(API_URL + match, payload)
            .then(response => {
                return response.data;
            })
    }

    update(id, sr) {
        return axios
            .post(API_UPDATE + id, sr, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    vereine() {
        return axios.get(API_URL + "vereine", {headers: authHeader()}).then(response => response.data)
    }
}

export default new GamesService();
