<template>
  <div class="col-md-12">
    <div class="card card-container">
      <form name="form" @submit.prevent="handleLogin">
        <h5 class="p-2 bg-darker text-black">Login</h5>
        <div class="form-group">
          <label for="username" class="text-black">E-Mail</label>
          <input
              v-model="user.email"
            type="email"
            class="form-control"
            name="email"
          />
        </div>
        <div class="form-group">
          <label for="password" class="text-black">Passwort</label>
          <input
              v-model="user.password"
            type="password"
            class="form-control"
            name="password"
          />
        </div>
        <div class="form-group mt-2">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Login</span>
          </button>
        </div>
        <div class="form-group mt-2">
          <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        </div>
        <div>
          <p>
            <router-link :to="{name: 'reset'}" class="text-decoration-none text-primary">Passwort vergessen?</router-link>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import User from '../models/user';
import {mapActions, mapGetters} from "vuex";
import router from '@/router/index';

console.log("LoginView")
export default {
  name: 'LoginView',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    ...mapGetters({
      "getAuth": "auth/getAuth"
    }),
    loggedIn() {
      return this.getAuth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      console.log("redirect")
      router.push('/');
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
     handleLogin() {
      this.loading = true;
       this.login( this.user).then(
          () => {
            router.replace({name: "home"});
          },
          error => {
            this.loading = false;
            this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
          })
}}}
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>