<template>
    <div class="AnswerView">
      <answer-list/>
    </div>
</template>

<script>
    import AnswerList from "@/components/AnswerList.vue";

    export default {
        name: 'AnswerView',
      components: {AnswerList}
    };
</script>
