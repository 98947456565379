<template>
    <div class="LinkView">
      <link-list />
    </div>
</template>

<script>
    import LinkList from "@/components/LinkList.vue";

    export default {
        name: 'LinkView',
      components: {LinkList}
    };
</script>
