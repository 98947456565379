import AuthService from '../services/auth.service';
import router from "@/router";


const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,
  state: {
    auth: initialState
  },
  getters:{
    getAuth(state){
      return state.auth
    },
    getUser(state){
      if(state.auth.user)
        return state.auth.user
      return null
    },
    getLoggedIn(state){
      if(state.auth.user && state.auth.user.expireDate){
        if(state.auth.user.expireDate > new Date()){
          return state.auth.status.loggedIn
        }
      }
      return false
    },
    getExpired(state) {
      if (state.auth.user && state.auth.user.expireDate) {
        if (state.auth.user.expireDate < new Date()) {
          return true
        }
      }
      return false
    },
    getAdmin(state) {
      return !!(state.auth.user && state.auth.user.roles.includes("ROLE_ADMIN"));

    }
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
      router.push({name: "home"})
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    updateUser(state, user){
      state.auth.user = {...state.auth.user, ...user}
      localStorage.setItem('user', JSON.stringify(state.auth.user));
    },
    loginSuccess(state, user) {
      state.auth.status.loggedIn = true;
      state.auth.user = user;
    },
    loginFailure(state) {
      state.auth.status.loggedIn = false;
      state.auth.user = null;
    },
    logout(state) {
      state.auth.status.loggedIn = false;
      state.auth.user = null;
    },
    registerSuccess(state) {
      state.auth.status.loggedIn = false;
    },
    registerFailure(state) {
      state.auth.status.loggedIn = false;
    }
  }
};
