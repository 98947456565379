<template>
  <div class="basar">
    <BasarList />
  </div>
</template>

<script>

import BasarList from "@/components/BasarList";

export default {
  name: 'HomeView',
  components: {
    BasarList,
  }
}
</script>
