const {format} = require("date-fns");


exports.pagination = {
    enabled: true,
    mode: 'records',
    perPage: 20,
    position: 'bottom',
    perPageDropdown: [],
    dropdownAllowAll: true,
    setCurrentPage: 1,
    nextLabel: 'Weiter',
    prevLabel: 'Vor',
    rowsPerPageLabel: 'Spiele pro Seite',
    ofLabel: 'von',
    pageLabel: 'Seite', // for 'pages' mode
    allLabel: 'Alle',

}
exports.sort =
    {
        enabled: true,
        initialSortBy: [
            { field: 'kickoffDate', type:   'asc'},
            { field: 'spielfeld', type:   'asc'},
            { field: 'kickoffTime', type: 'asc'}
        ]
}

exports.search = {
    enabled: true,
    trigger: 'keyup',
    skipDiacritics: true,
//    searchFn: mySearchFn,
    placeholder: 'Suchen',
    //  externalQuery: searchQuery
}
const fieldFn = exports.fieldFn = (data) => {
    if(data.liganame.includes("Herren")){
        if(data.liganame.includes("Kreisliga")){
            return "LSE"
        }
        return "LSD"
    }
    if(data.liganame.includes("Damen")) {
        if (data.liganame.includes("Bezirksliga")) {
            return "LSE"
        }
        if (data.liganame.includes("Landesliga")) {
            return "LSE"
        }
        return "LSD"
    }
    if(data.liganame.includes("Oberliga")){
        return "LSE+ | LSD"
    }
    if(data.liganame.includes("Playoffs")){
        return "LSE+ | LSD"
    }
    return "LSE"
}


exports.columns = [
    {
        label: 'Datum',
        field: 'kickoffDate',
        type: 'date',
        dateInputFormat: 'yyyy-MM-dd',
        dateOutputFormat: 'dd.MM.yyyy',
        tdClass: 'text-center',
        filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: function (data, filterString) {
                return format(new Date(data), "dd.MM.yyyy").includes(filterString)
            }, //custom filter function that
            trigger: 'keyup', //only trigger on enter not on keyup
        },
    },
    {
        label: 'Halle',
        field: 'spielfeld',
        filterOptions: {
            enabled: true, // enable filter for this column
        },
        tdClass: 'text-center'
    },
    {
        label: 'Zeit',
        field: 'kickoffTime',
        type: 'date',
        tdClass: 'text-center',
        dateInputFormat: 'HH:mm',
        dateOutputFormat: 'HH:mm',
        filterOptions: {
            enabled: true, // enable filter for this column
        },
    },
    {
        label: 'Lizenz',
        field: fieldFn,
        tdClass: 'text-center',
        filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: ["LSE", "LSE+ | LSD", "LSD"],
        },
    },
]
