<template>
    <div class="container mt-3">
      <div class="text-start ">
        <h3> <font-awesome-icon icon="fa-solid fa-list"/> Antworten</h3>
      </div>
      <p> Auf dieser Seite findest du die Antworten zum Link "{{this.$route.params.link}}"</p>
      <ul class="list-group">
        <li v-for="(answer, index) in answers" :key="index" class="list-group-item nocturnal text-white d-flex flex-wrap">
          <h4>Antwort {{index +1}}</h4>
          <div class="container-sm">
            <div class="btn-group my-3" role="group">

              <button type="button" class="btn btn-lg btn-outline-light" v-if="answer.name" disabled>{{answer.name}}</button>
              <button type="button" class="btn btn-lg btn-outline-light" v-if="answer.lizenzstufe" disabled>{{answer.lizenzstufe}}</button>
              <button type="button" class="btn btn-lg btn-outline-light" v-if="answer.email" disabled>{{answer.email}}</button>
              <button type="button" class="btn btn-lg btn-outline-light" v-if="answer.telefon" disabled>{{answer.telefon}}</button>
            </div>
            <div class="text-center" v-if="answer.message">
              <figure>
                <blockquote class="blockquote">
                  <p>{{answer.message}}</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                  zusätzliche Mitteilung von {{answer.name}}
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="container-sm">
            <ul class="list-group">
              <li v-for="(game, index) in answer.games" :key="index" class="list-group-item nocturnal text-white">
                  <div class="d-flex justify-content-evenly">
                    <div>
                      {{this.convertToGermanDate(game.kickoffDate)}}
                    </div>
                    <div>
                      {{game.kickoffTime}}
                    </div>
                    <div>
                      {{game.spielfeld}}
                    </div>
                    <div>
                      {{game.liganame}}
                    </div>
                    <div>
                      {{game.homeTeam}}
                    </div>
                    <div>
                      {{game.guestTeam}}
                    </div>
                  </div>
                <div class="d-flex justify-content-evenly">
                <div class="btn-group my-3" role="group"  v-if="getUser.club.includes(game.sr1)">
                  <button type="button" class="btn btn-outline-light" disabled>SR1</button>
                  <button type="button" class="btn" :class="{'btn-success' :game.sr1Besetzt && !game.sr1Basar, 'btn-warning': game.sr1Basar, 'btn-danger': !game.sr1Besetzt && !game.sr1Basar}" disabled>{{game.sr1}}</button>
                  <button type="button" class="btn btn-outline-light" disabled v-if="game.sr1Name">{{game.sr1Name}}</button>
                  <button type="button" class="btn btn-outline-light" disabled v-if="!game.sr1Name && game.sr1Besetzt">kein Name angegeben</button>
                  <button type="button" class="btn btn-success" v-if="game.sr1Besetzt && game.sr1Name === answer.name && game.sr1Mail === answer.email"  disabled>ist bereits angesetzt</button>
                  <button type="button" class="btn btn-outline-success" v-else-if="!game.sr1Besetzt" @click="submit(1, game, answer)">ansetzen</button>
                  <button type="button" class="btn btn-outline-danger" v-else @click="submit(1, game, answer)">trotzdem ansetzen</button>
                </div>
                  <div class="btn-group my-3" role="group"  v-else>
                    <button type="button" class="btn btn-outline-light" disabled>SR1</button>
                    <button type="button" class="btn btn-outline-light" disabled>{{game.sr1}}</button>
                    <button type="button" class="btn" :class="{'btn-success' :game.sr1Besetzt && !game.sr1Basar, 'btn-warning': game.sr1Basar, 'btn-danger': !game.sr1Besetzt && !game.sr1Basar}" disabled>{{getString(game.sr1Besetzt, game.sr1Basar)}}</button>
                  </div>
                <div class="btn-group my-3" role="group"  v-if="getUser.club.includes(game.sr2)">
                  <button type="button" class="btn btn-outline-light" disabled>SR2</button>
                  <button type="button" class="btn btn-outline-light" :class="{'btn-success' :game.sr2Besetzt && !game.sr2Basar, 'btn-warning': game.sr2Basar, 'btn-danger': !game.sr2Besetzt && !game.sr2Basar}" disabled>{{game.sr2}}</button>
                  <button type="button" class="btn btn-outline-light" disabled v-if="game.sr2Name">{{game.sr2Name}}</button>
                  <button type="button" class="btn btn-outline-light" disabled v-if="!game.sr2Name && game.sr2Besetzt">kein Name angegeben</button>
                  <button type="button" class="btn btn-success" v-if="game.sr2Besetzt && game.sr2Name === answer.name && game.sr2Mail === answer.email"  disabled>ist bereits angesetzt</button>
                  <button type="button" class="btn btn-outline-success" v-else-if="!game.sr2Besetzt" @click="submit(2, game, answer)">ansetzen</button>
                  <button type="button" class="btn btn-outline-danger" v-else @click="submit(2, game, answer)">trotzdem ansetzen</button>
                </div>
                  <div class="btn-group my-3" role="group"  v-else>
                    <button type="button" class="btn btn-outline-light" disabled>SR2</button>
                    <button type="button" class="btn btn-outline-light" disabled>{{game.sr2}}</button>
                    <button type="button" class="btn" :class="{'btn-success' :game.sr2Besetzt && !game.sr2Basar, 'btn-warning': game.sr2Basar, 'btn-danger': !game.sr2Besetzt && !game.sr2Basar}" disabled>{{getString(game.sr2Besetzt, game.sr2Basar)}}</button>
                  </div>
                </div>

              </li>
            </ul>
            <div class="text-center my-2">
              <button type="button" class="btn btn-outline-danger" @click="deleteAnswer(answer)">Antwort löschen</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
    import LinkService from "@/services/link.service";
    import {mapGetters} from "vuex";
    import GamesService from "@/services/games.service";
    export default {
        name: 'AnswerList',
        data(){
          return{
            answers: []
          }
        },
      computed:{
      ...mapGetters('auth', ['getUser']),
      },
      methods:{
          getString(besetzt, basar){
            if(!besetzt && !basar){
              return "offfen"
            }
            if(basar && !besetzt){
              return "im Basar"
            }
            if(besetzt){
              return "besetzt"
            }
          },
        async deleteAnswer(answer){
          if(confirm("Wirklich löschen?")){
            await LinkService.removeAnswer(answer._id)
            await this.getAnswers()
          }
          },
        async submit(sr, game, answer){
          if(confirm("Wirklich ansetzen?")){
          var obj = {
            sr2Besetzt: true,
            sr2Basar: false,
            sr2Info: "Link-Übernahme: Lizenz ( "+answer.lizenzstufe+" ) " + (answer.telefon ? "Telefon ( " +answer.telefon + " ) " : "") + (answer.message ? "zusätzliche Mitteilung ( " + answer.message + " ) " : ""),
            sr2Mail: answer.email,
            sr2Bonus: 0,
            sr2Name: answer.name
          }
          if (sr === 1) {
            obj = {
              sr1Besetzt: true,
              sr1Basar: false,
              sr1Info: "Link-Übernahme: Lizenz ( "+answer.lizenzstufe+" ) " + (answer.telefon ? "Telefon ( " +answer.telefon + " ) " : "") + (answer.message ? "zusätzliche Mitteilung ( " + answer.message + " ) " : ""),
              sr1Mail: answer.email,
              sr1Bonus: 0,
              sr1Name: answer.name
            }
          }
          await GamesService.update(game.matchId, obj)
          await this.getAnswers()}
        },
        async getAnswers(){
          this.answers = await LinkService.getAnswers(this.$route.params.link)
          if(this.answers.length === 0){
            this.$router.replace({name: "links"})
          }
        },
        convertToGermanDate(englishDateString) {
          // Aufteilen des Eingabe-Strings in Jahr, Monat und Tag
          const [year, month, day] = englishDateString.split('-');

          // Erstellen eines Date-Objekts
          const date = new Date(year, month - 1, day); // Monate sind in JavaScript 0-basiert

          // Überprüfen, ob das Date-Objekt gültig ist
          if (isNaN(date.getTime())) {
            return "Ungültiges Datum";
          }

          // Erstellen eines deutschen Datums-Strings
          const germanDateString = date.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });

          return germanDateString;
        }
      },
      async created(){
        if (!this.$route.params.link) {
          this.$router.replace({name: "home"})
        } else {
          await this.getAnswers()
      }
        }
    };
</script>
