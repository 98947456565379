<template>
    <div class="container mt-3">
      <div class="text-start ">
        <h3> <font-awesome-icon icon="fa-solid fa-list"/> Linkliste</h3>
      </div>
      <div class="input-group mb-3">
        <button class="btn btn-outline-light" disabled type="button" id="inputGroupFileAddon04">https://srbasar.de/link/...</button>
        <input type="text" class="form-control" :class="{'is-invalid' : invalid || already}" v-model="link" placeholder="...Link">
        <button class="btn btn-outline-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ lizenzstufe }}</button>

        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="lizenzstufe ='LSE'">LSE</a></li>
          <li><a class="dropdown-item" @click="lizenzstufe ='LSE+ | LSD'">LSE+ | LSD</a></li>
          <li><a class="dropdown-item" @click="lizenzstufe ='LSD'">LSD</a></li>
        </ul>
        <button class="btn btn-outline-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ clubs }}</button>

        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="clubs ='ALLE'">Alle meine Vereine</a></li>

          <li v-for="club of getUser.club" :key="club"><a class="dropdown-item" @click="clubs = club" :key="club">{{ club }}</a></li>

        </ul>
        <input type="checkbox" class="btn-check" id="onlyShow" v-model="onlyShow" autocomplete="off" >
        <label class="btn btn-outline-light"  for="onlyShow" ><font-awesome-icon icon="fa-solid fa-pen-to-square" size="xl" v-if="!onlyShow"/><font-awesome-icon icon="fa-solid fa-eye" size="xl" v-else/>{{ onlyShow ? "Eintragen nicht möglich" : "Eintragung möglich" }}</label>
      </div>

      <div class="input-group mb-3">
        <input type="date" class="form-control" :class="{'is-invalid' : invalid || already}" v-model="start" :min="(new Date()).toISOString().split('T')[0]" :max="`${(new Date).getFullYear() +1}-05-31`"  placeholder="Start">
        <input type="date" class="form-control" :class="{'is-invalid' : invalid || already}" v-model="ende" :min="start || (new Date()).toISOString().split('T')[0]"  :max="`${(new Date).getFullYear() +1}-05-31`" placeholder="Ende">
        <button class="btn btn-outline-light" type="button" @click="create">erstellen</button>
      </div>
    </div>
  <div>
    <div class="text-danger" v-if="invalid">Bitte Lizenzstufe auswählen oder Link eingeben.</div>
    <div class="text-danger" v-if="already">Link wird bereits von jemand anders verwendet, bitte einen anderen wählen.</div>
  </div>
  <div class="mt-5">
    <link-view-list :links="links" @update="getLinks"/>
  </div>
</template>

<script>
    import LinkService from "@/services/link.service";
    import {mapGetters} from "vuex";
    import LinkViewList from "@/components/LinkViewList.vue";

    export default {
        name: 'LinkList',
      components: {LinkViewList},
        data(){
          return{
            lizenzstufe: "LSD",
            link: null,
            onlyShow: false,
            invalid: false,
            already: false,
            start: (new Date()).toISOString().split('T')[0],
            clubs: "ALLE",
            ende: null,
            links: []
          }
        },
      computed:{
      ...mapGetters('auth', ['getUser']),
      },
      methods:{
          async create(){
            this.invalid = false;
            this.already = false;
              if(!this.link || this.lizenzstufe === "Lizenzstufe"){
                this.invalid = true;
                return
              }
             const res =  await LinkService.add({alias: this.link,  onlyShow: this.onlyShow, start: this.start, end: this.ende, lizenzstufe: this.lizenzstufe, verein: this.clubs})
            if(res === false){
              this.already = true;
            }else{
              await this.getLinks()
            }
          },
        async getLinks(){
          this.links = await LinkService.get()
        }
      },
      async created(){
          await this.getLinks()
      }
    };
</script>
