import axios from 'axios';
import authHeader from "@/services/auth-header";
import store from '../store/';

const API_URL = 'https://api.srbasar.de/api/link/';


//const API_URL = 'http://localhost:3000/api/link/';


class LinkService {
  get(onlyShow) {
    return axios
        .post(API_URL, {onlyShow: onlyShow}, { headers: authHeader() } )
      .then(response => {
          return response.data;
      }).catch(async () => {
            await store.dispatch('auth/logout')
        });
  }

    getGames(link) {
        return axios
            .post(API_URL + "get/" + link, {})
            .then(response => {
                return response.data;
            }).catch(() =>{
                return false;
            })
    }
    getAnswers(link) {
        return axios
            .get(API_URL + "getAnswer/" + link, {headers: authHeader()})
            .then(response => {
                return response.data.answers;
            }).catch(() =>{
                return false;
            })
    }
    answer(payload) {
        return axios
            .post(API_URL + 'answer', payload  )
            .then(response => {
                return response.data;
            })
    }

    add(payload) {
        return axios
            .post(API_URL + 'add',payload, { headers: authHeader() } )
            .then(response => {
                return response.data;
            }).catch((e) => {console.log(e); return false} )
    }
    remove(id) {
        return axios
            .delete(API_URL + 'remove/'+id , { headers: authHeader() } )
            .then(response => {
                return response.data;
            }).catch((e) => {console.log(e); return false} )
    }
    removeAnswer(id) {
        return axios
            .delete(API_URL + 'removeAnswer/'+id , { headers: authHeader() } )
            .then(response => {
                return response.data;
            }).catch((e) => {console.log(e); return false} )
    }

    vereine() {
        return axios.get(API_URL + "vereine", {headers: authHeader()}).then(response => response.data)
    }
}

export default new LinkService();
