<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link :to="{ name: 'home'}"  active-class="" class="navbar-brand">BBV-Spielebasar</router-link>
      <button aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-bs-target="#navbarNavDropdown" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarNavDropdown" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link"  :to="{ name: 'home'}">Spielebasar</router-link>
          </li>
          <li v-if="getLoggedIn" class="nav-item" :class="{'active' : currentRoute === 'ansetzungen'}">
            <router-link :to="{ name: 'ansetzungen'}" class="nav-link">Vereinsansetzungen</router-link>
          </li>
          <li v-if="getLoggedIn" class="nav-item" :class="{'active' : currentRoute === 'historie'}">
            <router-link :to="{ name: 'historie'}" class="nav-link">Historie</router-link>
          </li>
          <li v-if="getLoggedIn" class="nav-item" :class="{'active' : currentRoute === 'links'}">
            <router-link :to="{ name: 'links'}" class="nav-link">Links</router-link>
          </li>
          <li v-if="getLoggedIn" class="nav-item" :class="{'active' : currentRoute === 'all'}">
            <router-link :to="{ name: 'all'}" class="nav-link">Alle Spiele</router-link>
          </li>
          <li v-if="getLoggedIn" class="nav-item" :class="{'active' : currentRoute === 'profile'}">
            <router-link :to="{ name: 'profile'}" class="nav-link">Mein Profil</router-link>
          </li>
          <li v-if="getAdmin" class="nav-item" :class="{'active' : currentRoute === 'user'}">
            <router-link :to="{ name: 'user'}" class="nav-link">Benutzer</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li v-if="!getLoggedIn" class="nav-item">
            <router-link :to="{name: 'login'}" class="nav-link">VSRW Login</router-link>
          </li>
          <li v-else class="nav-item">
            <span class="nav-link link-out" @click="logMeOut">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AuthService from "@/services/auth.service";
export default {
  computed: {
    ...mapGetters(
        {getLoggedIn: "auth/getLoggedIn",
        getAdmin: "auth/getAdmin",
          getAuth: "auth/getAuth"}),
    currentRoute() {
      return this.$route.name;
    }
    },
  mounted(){
    if (!this.getLoggedIn) {
      this.logoutMutation()
      AuthService.logout()
    }
  },
    methods: {
      ...mapActions({logoutAction: "auth/logout"}),
      ...mapMutations({logoutMutation: "auth/logout"}),
      logMeOut() {
        this.logoutAction()
      }
    }
  }

</script>
<style>

.link-out {
  cursor: pointer;
}
.router-link-active{
  color: white !important;
  text-decoration: underline !important;}

</style>
