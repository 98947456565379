<template>
  <div class="col-md-12">

    <div class="card card-container">
      <form name="form" @submit.prevent="handleReset">
        <h5 class="p-2 bg-darker text-black">Passwort vergessen</h5>
        <div class="form-group">
          <label for="username" class="text-black">E-Mail</label>
          <input
              v-model="email"
              type="email"
              class="form-control"
              name="email"
          />
        </div>

        <div class="form-group mt-2">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Passwort zurücksetzen</span>
          </button>
        </div>
        <div class="form-group mt-2">
          <div v-if="message" class="alert alert-success" role="alert">{{ message }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import router from '@/router/index';
import AuthService from "@/services/auth.service";


export default {
  name: 'ResetView',
  data() {
    return {
      email: "",
      loading: false,
      message: ''
    };
  },
  computed: {
    ...mapGetters({
      "getAuth": "auth/getAuth"
    }),
    loggedIn() {
      return this.getAuth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      router.push('/profile');
    }
  },
  methods: {
    handleReset() {
      this.loading = true;
      AuthService.reset(this.email)
      this.loading = false;
      this.message = "Sollte es einen Benutzer mit dieser E-Mail-Adresse geben, wurde dir gerade ein neues Passwort zugesendet. Bitte überprüfe auch deinen Spam-Ordner."
    }
  }
}
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>